import React from 'react';
import { Heading } from '@atoms';
import { withTransition } from '@hocs';
import { HeadingLevel, TextColor } from '@type';
import { PageLoader } from '@molecules';
import {
  Columns, ContentContainer, Content, Sidebar as SidebarComponent,
} from './sidebar.styled';
import { LayoutContainer } from '../components';

interface Props {
  title?: string;
  titleComponent?: JSX.Element;
  subtitle?: string;
  sidebar?: JSX.Element | null;
  className?: string;
  classNameForSideBar?: string;
  loading?: boolean;
  transition?: boolean;
}

const Sidebar: React.FC<Props> = ({
  sidebar,
  title,
  titleComponent,
  subtitle,
  children,
  className,
  classNameForSideBar,
  loading = false,
}) => (
  <LayoutContainer className={className}>
    <ContentContainer>
      {title && <Heading level={HeadingLevel.H1}>{title}</Heading>}
      {titleComponent && titleComponent}
      {subtitle && (
        <Heading level={HeadingLevel.H3} color={TextColor.Sub}>{subtitle}</Heading>
      )}
      <Columns className="content">
        <Content>
          {loading && <PageLoader />}
          <>
            {!loading && children}
          </>
        </Content>
        <SidebarComponent className={classNameForSideBar}>
          {sidebar && sidebar}
        </SidebarComponent>
      </Columns>
    </ContentContainer>
  </LayoutContainer>
);

export const SidebarNoTransition: React.FC<Props> = ({
  children,
  className,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Sidebar {...props} className={`${className} page-enter-done`}>
    {children}
  </Sidebar>
);

export default withTransition(Sidebar);

import styled, { css } from 'styled-components';
import { media } from '@utils';

export const ContentContainer = styled.main`
  max-width: 67.5rem;
  flex-basis: 100%;

  .display-none {
    display: none;
  }
`;

export const Columns = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;

  ${media.xl(css`
    grid-template-columns: 2fr 1fr;
    grid-template-rows: auto;
    gap: 2rem;
  `)};
`;

export const Content = styled.section`
  margin: 0;
  grid-area: 1 / 1 / 2 / 2;

  ${media.xl(css`
    margin-left: 2rem;
    @supports(gap: 2rem) {
      margin-left: 0;
    }
  `)};
`;

export const Sidebar = styled.section`
  grid-area: 2 / 1 / 3 / 2;

  ${media.xl(css`
    grid-area: 1 / 2 / 2 / 3;
  `)};
`;

import { GetTranslation } from '@hooks/i18n/types';

export const minValue = (
  t: GetTranslation,
) => (min: number) => (value: string): string | true => (parseInt(value, 10) < min ? t('form-number-minimal-input', {
  replace: { '%min%': min.toString() },
  notes: 'Minimal value error message for number input',
}) : true);

export const maxValue = (
  t: GetTranslation,
) => (max: number) => (value: string): string | true => (parseInt(value, 10) > max ? t('form-number-maximal-input', {
  replace: { '%max%': max.toString() },
  notes: 'Maximal value error message for number input',
}) : true);

export const betweenMinMaxValue = (
  t: GetTranslation,
) => (min: number, max: number) => (value: string): string | true => (parseInt(value, 10) < min || parseInt(value, 10) > max ? t('form-field-validation-minimal-maximum-number', {
  replace: {
    '%min%': min.toString(),
    '%max%': max.toString(),
  },
  notes: 'Value range error message for number input',
}) : true);

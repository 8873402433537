import React from 'react';
import { useForm } from 'react-hook-form';
import { isEmail } from '@utils/formValidators';
import {
  CardActions, CardContent, CardHeader, P, Link, ActionButton,
} from '@atoms';
import { Card } from '@molecules';
import { InputRHFField, PasswordRHFField } from '@molecules/form';
import { useI18n } from '@hooks/i18n';
import { useAuthentication } from '@hooks/authentication';
import InputWrapper from './loginForm.styled';

interface Props {
  onSubmit: (...args: Array<any>) => any;
}

export const LoginForm: React.FC<Props> = ({
  onSubmit,
}) => {
  const { t } = useI18n();
  const { loading } = useAuthentication();
  const { register, handleSubmit, errors } = useForm();

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <Card
        renderHeader={() => <CardHeader>{t('login')}</CardHeader>}
        renderFooter={() => (
          <CardActions>
            <Link href="/reset/">{t('reset-password')}</Link>
            <ActionButton loading={loading} disabled={loading}>
              {t('form-login-save')}
            </ActionButton>
          </CardActions>
        )}
      >
        <CardContent>
          <P>{t('login-description')}</P>
          <InputWrapper>
            <InputRHFField
              label={t('form-email-label')}
              name="email"
              type="email"
              fieldRef={register({
                required: t('form-required'),
                validate: (email: string) => (isEmail(email) || t('form-email-invalid')),
              })}
              placeholder={t('form-email-placeholder')}
              error={errors.email}
            />
            <PasswordRHFField
              name="password"
              placeholder={t('form-password-placeholder')}
              label={t('form-password-label')}
              fieldRef={register({
                required: t('form-required'),
              })}
              showToggleButton
              error={errors.password}
            />
          </InputWrapper>
        </CardContent>
      </Card>
    </form>
  );
};

import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Highlight as HighlightLayout } from '@layouts';
import { useI18n } from '@hooks/i18n';
import { useAuthentication } from '@hooks/authentication';
import { LoginForm } from '@organisms/loginForm';
import { MessageType } from '@constants/messageStack';
import { useMessageStack } from '@hooks/messageStack';

type DispatchProps = {
  setMessage?: (error: string, type: MessageType) => Promise<any>;
};

const LoginPage: React.FC<DispatchProps> = () => {
  const { t } = useI18n();
  const { infoMessage } = useMessageStack();
  const { push, query } = useRouter();
  const {
    error,
    isAuthenticated,
    updateCredentials,
  } = useAuthentication();

  if (isAuthenticated) {
    if (query.redirect) {
      push(query.redirect as string);
    } else {
      push('/dashboard');
    }
    return null;
  }

  useEffect(() => {
    if (error) {
      infoMessage(t(error), MessageType.Error);
    }
  }, [error]);

  return (
    <HighlightLayout>
      <LoginForm onSubmit={({
        email,
        password,
      }) => updateCredentials({ username: email, password })}
      />
    </HighlightLayout>
  );
};

export default LoginPage;

import React from 'react';
import styled, { css } from 'styled-components';
import { withTransition } from '@hocs';
import { media } from '@utils';
import { Heading } from '@atoms';
import { HeadingLevel } from '@type';
import { LayoutContainer } from '../components';

interface Props {
  leftComponent?: () => JSX.Element;
  rightComponent?: () => JSX.Element;
  titleComponent?: JSX.Element;
  title?: string;
  center?: boolean;
  className?: string;
}

export const ContentContainer = styled.div<Props>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto;
  max-width: 67.5rem;
  ${media.lg(css`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
  `)}

  ${({ center }) => center && css`
    margin: 0 auto;
  `}

  @supports (gap: 2rem) {
    gap: 1.5rem;
  }
`;

export const Column = styled.section`
  padding: 1rem;

  @supports (gap: 2rem) {
    padding: 0;
  }

  &:nth-of-type(1) {
    grid-area: 1 / 1 / 2 / 2;
  }

  &:nth-of-type(2) {
    grid-area: 2 / 1 / 3 / 2;

    ${media.lg(css`
      grid-area: 1 / 2 / 2 / 3;
    `)}
  }
`;

const Split: React.FC<Props> = ({
  leftComponent,
  rightComponent,
  titleComponent,
  title,
  center = false,
  className,
}) => (
  <LayoutContainer center={center} className={className}>
    <div className="wrapper">
      {title && <Heading level={HeadingLevel.H1}>{title}</Heading>}
      {titleComponent && titleComponent}
      <ContentContainer className="content">
        <Column>
          {leftComponent && leftComponent()}
        </Column>
        <Column>
          {rightComponent && rightComponent()}
        </Column>
      </ContentContainer>
    </div>
  </LayoutContainer>
);

export default withTransition(Split);

import React from 'react';
import styled from 'styled-components';
import { Heading } from '@atoms';
import { withTransition } from '@hocs';
import { HeadingLevel, TextColor } from '@type';
import { LayoutContainer } from '../components';

export const ContentContainer = styled.section`
  max-width: 42rem;
  width: 100%;
`;

interface Props {
  title?: string;
  subtitle?: string;
  titleComponent?: JSX.Element;
  className?: string;
}

const Single: React.FC<Props> = ({
  title,
  subtitle,
  titleComponent,
  children,
  className,
}) => (
  <LayoutContainer className={className}>
    <ContentContainer>
      {title && <Heading level={HeadingLevel.H1}>{title}</Heading>}
      {titleComponent && titleComponent}
      {subtitle && <Heading level={HeadingLevel.H3} color={TextColor.Sub}>{subtitle}</Heading>}
      <main className="content">
        {children}
      </main>
    </ContentContainer>
  </LayoutContainer>
);

export const SingleNoTransition: React.FC<Props> = ({
  children,
  className,
  ...props
}) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Single {...props} className={`${className} page-enter-done`}>
    {children}
  </Single>
);

export default withTransition(Single);

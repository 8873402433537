import React from 'react';
import { withTransition } from '@hocs';
import { Highlights } from '@organisms';
import { ContentContainer, Column } from '../split';
import { LayoutContainer } from '../components';

interface Props {
  className?: string;
}

const Highlight: React.FC<Props> = ({
  children,
  className,
}) => (
  <LayoutContainer center className={className}>
    <ContentContainer className="content">
      <Column>
        {children}
      </Column>
      <Column>
        <Highlights />
      </Column>
    </ContentContainer>
  </LayoutContainer>
);

export default withTransition(Highlight);

import { isDateInTheFuture } from '@utils/dates';
import { GetTranslation } from '@hooks/i18n/types';

const currentYear = new Date().getFullYear();

export const validBuildYear = (
  t: GetTranslation,
) => (value: string) => (parseInt(value, 10) < currentYear - 50 || parseInt(value, 10) > currentYear ? t('form-field-validation-must-be-valid-buildyear') : true);

export const dateIsNotInFuture = (
  t: GetTranslation,
) => (value: string) => (isDateInTheFuture(new Date(value)) ? t('form-field-validation-date-not-in-future') : true);

export const date = (errorMessage: string) => (value: string) => (value && !/^(?:(?:31(-)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(-)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(-)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(-)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/i.test(value) ? (errorMessage || 'Invalid date') : undefined);

export const dateMultiField = (errorMessage: string) => (dateFields: {
  year: string;
  month: string;
  day: string;
}) => (values: {
  [key: string]: string;
}) => {
  if (values && values[dateFields.year] && values[dateFields.month] && values[dateFields.day]) {
    // All required strings are present

    const d = new Date(
      Date.UTC(
        parseInt(values[dateFields.year], 10), parseInt(values[dateFields.month], 10) - 1,
        parseInt(values[dateFields.day], 10),
      ),
    );

    if (d && d.getMonth() + 1 === parseInt(values[dateFields.month], 10)) {
      // Check whether month is equal. This is enough to catch errors like 600-04-2018,
      // because JS's month automatically adds up based on the number of days.
      return undefined;
    }
  }

  return errorMessage || 'Invalid date';
};

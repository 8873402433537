import { GetTranslation } from '@hooks/i18n';

// eslint-disable-next-line no-control-regex
const regEmail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const isEmail = (email: string) => regEmail.test(email);

export const email = (errorMessage: string) => (value: string) => (value && isEmail(value) ? undefined : (errorMessage || 'Invalid email'));

export const validEmail = (
  t: GetTranslation,
) => (value: string): string | true => (
  !isEmail(value)
    ? t('form-field-validation-email', {
      notes: 'Message for invalid email address',
    })
    : true
);

export const compareEmail = (
  t: GetTranslation,
  emailToCompare: string,
) => (value: string): string | true => (
  value.toLowerCase() === emailToCompare.toLowerCase()
    ? t('form-field-validation-different-email', {
      notes: 'Message when email is not different',
    })
    : true
);

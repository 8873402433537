import styled, { css } from 'styled-components';

interface Props {
  center?: boolean;
}

export const LayoutContainer = styled.section<Props>`
  display: flex;
  justify-content: left;
  ${({ center }) => center && css`
    justify-content: center;
  `}
  .wrapper {
    width: 100%;
  }

  .content {
    opacity: 0;
    transition: opacity 0.6s ease;

    &.page-enter-done {
      opacity: 1;
    }
  }

  .page-header {
    opacity: 0;
    transform: translateX(-3rem);
    transition:
      opacity 0.5s ease 0.2s,
      transform 0.5s ease 0.2s;

    &.page-enter-done {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.page-enter-done {
    .content {
      opacity: 1;
    }

    .page-header {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &.item-exited {
    .page-header {
      transform: translateX(-3rem);
      opacity: 1;
    }
  }
`;
